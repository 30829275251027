import React from "react"
import PostCard from "../PostCard/PostCard"
import * as styles from "./PostsList.module.scss"

const PostsList = ({ posts, type, thumbnailSize, title }) => {
  return (
    <section className={`${styles.PostsList} ${thumbnailSize && styles.thumbnails}`}>
      {title && <h2>{title}</h2>}
      <div className={styles.grid}>
        {posts?.map((post, i) => {
          return (
            <PostCard
              type={type}
              key={post.id}
              featured={!thumbnailSize && (i + 1) % 4 === 0}
              thumbnailSize={thumbnailSize}
              {...post}
            />
          )
        })}
      </div>
    </section>
  )
}

export default PostsList
