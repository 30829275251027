import { motion } from "framer-motion"
import React from "react"
import { Image } from "react-datocms"
// import { Image } from "react-datocms";
import Link from "next/link"
import * as styles from "./PostCard.module.scss"
import noimage from "@images/icons/cameraOrange.svg"

const PostCard = ({
  slug,
  title,
  featuredImage,
  author,
  type = "top-tips",
  thumbnailSize = false,
  featured = false,
}) => (
  <motion.div
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.2 }}
    className={`${styles.PostCard} ${thumbnailSize && styles.thumbnails} ${featured && styles.featured}`}
  >
    <Link href={`/${type}/${slug}`}>
      <a>
        <div className={styles.inner}>
          <div className={styles.image}>
            {featuredImage?.responsiveImage ? (
              <Image data={featuredImage.responsiveImage} className={styles.img} />
            ) : (
              <img src={noimage} className={styles.img} alt="" />
            )}
          </div>
          <div className={styles.info}>
            <h3>{title}</h3>
            <span>By{author ? ` ${author.name}` : " HOPP"}</span>
          </div>
        </div>
      </a>
    </Link>
  </motion.div>
)

export default PostCard
