import { Auth } from "aws-amplify"
import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "next/router"
import ChangePartyCategoryModal from "./ChangePartyCategoryModal"
import CreatePartyModal from "./CreatePartyModal"
import LoadingParty from "./LoadingParty"
import * as styles from "./NewPartyModal.module.scss"
import SelectPartyServices from "./SelectPartyServices"
import SelectStyle from "./SelectStyle"
import { eventGtag } from "@lib/gtag"
import fetchLambda from "@data/fetchLambda"
import lambdas from "@data/lambdas"
import * as actions from "@store/actions"
import ReactModal from "@components/global/ReactModal/ReactModal"

const { PARTY_SUBMIT, PARTY_SUPPLIER_SUBMIT, SUPPLIER_SEARCH } = lambdas[process.env.NEXT_PUBLIC_ENV]

class NewPartyModal extends Component {
  state = {
    step: "category",
    modalIsOpen: false,
    party: {
      partyName: "",
      partyLocation: "",
      partyDate: "0",
      partyStatus: 1,
      partyServices: [],
      partyStyle: "",
    },
  }

  openModal = () => {
    this.setState({ modalIsOpen: true })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
    this.resetState()
  }

  changeModal = (step) => {
    this.setState({ step: step })
  }

  handleChange = (event) => {
    let target = event.target
    let value
    let name

    value = target.type === "checkbox" ? target.checked : target.value
    name = target.name

    const newParty = { ...this.state.party }

    newParty[name] = value

    this.setState({
      party: newParty,
    })
  }

  //Multiselect input
  handleChangeSelect = (selected, type) => {
    const newParty = { ...this.state.party }

    newParty[type.name] = selected.value

    this.setState({
      party: newParty,
    })
  }

  handleChangeLocation = (newParty) => {
    let formatParty = { ...newParty }
    if (formatParty.location) {
      formatParty.partyLocation = JSON.stringify(formatParty.location)
    } else if (formatParty.partyLocation) {
      delete formatParty.partyLocation
    }
    this.setState({
      party: formatParty,
    })
  }

  handleChangeParty = (name, value) => {
    this.setState((prevState) => ({
      party: {
        ...prevState.party,
        [name]: value,
      },
    }))

    if (name === "partyStyle") {
      this.setState({
        step: "createparty",
      })
    }
  }

  handleUpdatepartyServices = (value, append) => {
    this.setState((prevState) => {
      let partyServices = prevState.party.partyServices
      if (append) {
        partyServices = [...partyServices, value]
      } else {
        partyServices = partyServices.filter((el) => el !== value)
      }
      return {
        party: {
          ...prevState.party,
          partyServices,
        },
      }
    })
  }

  resetState = () => {
    this.setState({
      step: "category",
      party: {
        partyName: "",
        partyLocation: "",
        partyDate: "0",
        partyServices: [],
        partyStyle: "",
        partyStatus: 1,
      },
    })
  }

  handleCreateParty = async () => {
    this.setState({ step: "loading" })

    const user = await Auth.currentSession()
    const newparty = this.state.party
    //create party
    const partyResponse = await fetchLambda(PARTY_SUBMIT, {
      sessionToken: user.getIdToken().getJwtToken(),
      data: newparty,
    })
    const party = partyResponse.data.party

    //get list of suppliers based off services
    if (newparty.partyServices && newparty.partyServices.length) {
      for (const service of newparty.partyServices) {
        const supplierSearch = await fetchLambda(SUPPLIER_SEARCH, {
          params: {
            first: 2,
            orderBy: "rank",
            order: "DESC",
            skip: 0,
            category: newparty.partyCategory,
            services: [service],
            style: newparty.partyStyle,
            //todo add location
            // "location",
          },
        })

        const generatedSuppliers = supplierSearch.data.result.allSuppliers

        //add suppliers to party
        if (generatedSuppliers && generatedSuppliers.length) {
          this.props.partyGeneratedSuppliersChange(true)
          for (const supplier of generatedSuppliers) {
            const partySupplierResponse = await fetchLambda(PARTY_SUPPLIER_SUBMIT, {
              sessionToken: user.getIdToken().getJwtToken(),
              data: {
                createdAt: party.partyId,
                supplierId: supplier.id,
                isAdding: true,
              },
            })
          }
        }
      }
    }

    this.props.partyUpdate(party)

    //analytics
    const { categoryOptions } = this.props.staticData
    const categoryData = categoryOptions.find((category) => category.value === party.partyCategory)
    eventGtag({
      action: "Create",
      params: {
        event_category: "Party",
        event_label: categoryData ? categoryData.label : "",
        value: party.partyId,
      },
    })
    this.props.router.push(`/party/${party.partyId}`)
    this.closeModal()
  }

  getModalSize = () => {
    switch (this.state.step) {
      case "createparty":
      case "loading":
        return ""
      default:
        return styles.largeModal
    }
  }

  render() {
    return (
      <div>
        <ReactModal
          button={this.props.button}
          modalLabel="New Party"
          onRequestClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          openModal={this.openModal}
          className={this.getModalSize()}
        >
          <section className={styles.NewPartyModal}>
            <div>
              {(() => {
                switch (this.state.step) {
                  case "createparty":
                    return (
                      <CreatePartyModal
                        changeModal={this.changeModal}
                        party={this.state.party}
                        partyCategory={
                          this.state.party && this.state.party.partyCategory
                            ? this.state.party.partyCategory
                            : this.props.search.category
                        }
                        handleCreateParty={this.handleCreateParty}
                        handleChange={this.handleChange}
                        handleChangeSelect={this.handleChangeSelect}
                        handleChangeParty={this.handleChangeParty}
                        handleChangeLocation={this.handleChangeLocation}
                      />
                    )
                  case "category":
                    return (
                      <ChangePartyCategoryModal
                        changeModal={this.changeModal}
                        handleChangeParty={this.handleChangeParty}
                      />
                    )
                  case "services":
                    return (
                      <SelectPartyServices
                        changeModal={this.changeModal}
                        partyServices={this.state.party.partyServices}
                        handleUpdatepartyServices={this.handleUpdatepartyServices}
                      />
                    )
                  case "style":
                    return (
                      <SelectStyle
                        changeModal={this.changeModal}
                        partyStyle={this.state.party.partyStyle}
                        handleChangeParty={this.handleChangeParty}
                      />
                    )
                  case "loading":
                    return (
                      <LoadingParty
                        title={`Creating your party`}
                        text="Finding the perfect suppliers"
                        callback={() => true}
                      />
                    )

                  default:
                    return null
                }
              })()}
            </div>
          </section>
        </ReactModal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    search: state.search.parameters,
    staticData: state.staticData.data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    partyUpdate: (party) => dispatch(actions.partyUpdate(party)),
    partyGeneratedSuppliersChange: (hasGeneratedSuppliers) =>
      dispatch(actions.partyGeneratedSuppliersChange(hasGeneratedSuppliers)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewPartyModal))
